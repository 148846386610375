define("ember-composability-tools/templates/render-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "enP787Kf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[35,1]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",[29]]],[[\"default\"],[{\"statements\":[[18,1,[[35,0]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"yieldHash\",\"destinationElement\",\"-in-el-null\",\"in-element\",\"shouldRender\",\"if\"]}",
    "moduleName": "ember-composability-tools/templates/render-block.hbs"
  });

  _exports.default = _default;
});