define("ember-layout-components/components/layout/center", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class={{
      layout-join-classes
      'layout-center'
      (layout-class-if @horizontal false '' 'layout-center--horizontal')
      (layout-class-if @vertical true 'layout-center--vertical')
    }}
    ...attributes
  >
    {{yield}}
  </div>
  */
  {
    "id": "8ln5TENe",
    "block": "{\"symbols\":[\"@vertical\",\"@horizontal\",\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[30,[36,1],[\"layout-center\",[30,[36,0],[[32,2],false,\"\",\"layout-center--horizontal\"],null],[30,[36,0],[[32,1],true,\"layout-center--vertical\"],null]],null]],[17,3],[12],[2,\"\\n  \"],[18,4,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"layout-class-if\",\"layout-join-classes\"]}",
    "moduleName": "ember-layout-components/components/layout/center.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});