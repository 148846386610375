define("ember-apollo-client/index", ["exports", "ember-apollo-client/-private/apollo/query-manager", "ember-apollo-client/services/apollo"], function (_exports, _queryManager, _apollo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "QueryManager", {
    enumerable: true,
    get: function get() {
      return _queryManager.default;
    }
  });
  Object.defineProperty(_exports, "queryManager", {
    enumerable: true,
    get: function get() {
      return _queryManager.queryManager;
    }
  });
  Object.defineProperty(_exports, "getObservable", {
    enumerable: true,
    get: function get() {
      return _apollo.getObservable;
    }
  });
  Object.defineProperty(_exports, "unsubscribe", {
    enumerable: true,
    get: function get() {
      return _apollo.unsubscribe;
    }
  });
});