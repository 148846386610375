define("ember-svg-jar/inlined/map-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 12.969a5.319 5.319 0 005.313-5.313A5.319 5.319 0 008 2.344a5.318 5.318 0 00-5.313 5.312A5.319 5.319 0 008 12.97zm0-10.04a4.732 4.732 0 014.727 4.727A4.732 4.732 0 018 12.383a4.732 4.732 0 01-4.727-4.727A4.732 4.732 0 018 2.93z\" fill=\"#000\"/><path d=\"M8 0C6.18 0 4.417.65 3.034 1.83a.293.293 0 00.38.445A7.071 7.071 0 018 .585a7.078 7.078 0 017.07 7.071 7.018 7.018 0 01-1.364 4.176l-5.468 7.46-.002.002a.293.293 0 01-.473 0l-5.467-7.46-.002-.002A7.015 7.015 0 01.93 7.655c0-1.68.6-3.308 1.689-4.585a.293.293 0 10-.446-.38 7.658 7.658 0 00-1.83 4.965c0 1.64.512 3.203 1.479 4.522l.001-.001c.018.028 5.469 7.464 5.469 7.464A.886.886 0 008 20a.882.882 0 00.708-.36c.02-.025 5.471-7.462 5.471-7.462a7.6 7.6 0 001.477-4.522C15.656 3.435 12.222 0 8 0z\" fill=\"#000\"/>",
    "attrs": {
      "width": "16",
      "height": "20",
      "viewBox": "0 0 16 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg",
      "data-ember-extension": "1"
    }
  };
  _exports.default = _default;
});