define("ember-layout-components/helpers/layout-css-var", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function layoutCssVar(_, hash) {
    var styles = [];
    Object.keys(hash).forEach(function (varName) {
      var value = hash[varName];

      if (!Ember.isNone(value)) {
        styles.push("--".concat(varName, ": ").concat(value));
      }
    });
    return styles.length > 0 ? Ember.String.htmlSafe(styles.join('; ')) : null;
  });

  _exports.default = _default;
});