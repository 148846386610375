define("ember-layout-components/helpers/layout-join-classes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function layoutJoinClasses(classNames) {
    return classNames.filter(Boolean).join(' ');
  });

  _exports.default = _default;
});