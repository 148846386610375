define("ember-layout-components/components/layout/vertical-stack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{layout-deprecate
    '@size is deprecated, use @gap instead'
    (if @size false true)
    id='ember-layout-components.vertical-stack-size'
    until='0.10.0'
  }}
  
  <div
    class={{
      layout-join-classes
      'layout-vertical-stack'
      (layout-class-if @size 'xlarge' 'layout-vertical-stack--xlarge')
      (layout-class-if @size 'large' 'layout-vertical-stack--large')
      (layout-class-if @size 'small' 'layout-vertical-stack--small')
      (layout-class-if @size 'xsmall' 'layout-vertical-stack--xsmall')
      (layout-class-if @gap 'xlarge' 'layout-vertical-stack--xlarge')
      (layout-class-if @gap 'large' 'layout-vertical-stack--large')
      (layout-class-if @gap 'small' 'layout-vertical-stack--small')
      (layout-class-if @gap 'xsmall' 'layout-vertical-stack--xsmall')
      (layout-class-if
        @withSeparator true 'layout-vertical-stack--with-separator'
      )
    }}
    ...attributes
  >
    {{yield (component 'layout/vertical-stack/item')}}
  </div>
  */
  {
    "id": "8Dm++RRx",
    "block": "{\"symbols\":[\"@size\",\"@withSeparator\",\"@gap\",\"&attrs\",\"&default\"],\"statements\":[[1,[30,[36,1],[\"@size is deprecated, use @gap instead\",[30,[36,0],[[32,1],false,true],null]],[[\"id\",\"until\"],[\"ember-layout-components.vertical-stack-size\",\"0.10.0\"]]]],[2,\"\\n\\n\"],[11,\"div\"],[16,0,[30,[36,3],[\"layout-vertical-stack\",[30,[36,2],[[32,1],\"xlarge\",\"layout-vertical-stack--xlarge\"],null],[30,[36,2],[[32,1],\"large\",\"layout-vertical-stack--large\"],null],[30,[36,2],[[32,1],\"small\",\"layout-vertical-stack--small\"],null],[30,[36,2],[[32,1],\"xsmall\",\"layout-vertical-stack--xsmall\"],null],[30,[36,2],[[32,3],\"xlarge\",\"layout-vertical-stack--xlarge\"],null],[30,[36,2],[[32,3],\"large\",\"layout-vertical-stack--large\"],null],[30,[36,2],[[32,3],\"small\",\"layout-vertical-stack--small\"],null],[30,[36,2],[[32,3],\"xsmall\",\"layout-vertical-stack--xsmall\"],null],[30,[36,2],[[32,2],true,\"layout-vertical-stack--with-separator\"],null]],null]],[17,4],[12],[2,\"\\n  \"],[18,5,[[30,[36,4],[\"layout/vertical-stack/item\"],null]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"layout-deprecate\",\"layout-class-if\",\"layout-join-classes\",\"component\"]}",
    "moduleName": "ember-layout-components/components/layout/vertical-stack.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});