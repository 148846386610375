define("ember-svg-jar/inlined/star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10 .49l3.09 6.26L20 7.756l-5 4.874 1.18 6.882L10 16.26l-6.18 3.25L5 12.629 0 7.755l6.91-1.004L10 .489z\" fill=\"#EFCE4A\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});