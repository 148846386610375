define("ember-layout-components/components/layout/grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class="layout-grid"
    style={{layout-css-var grid-gap-size=@gapSize grid-size=@gridSize}}
    ...attributes
  >
    {{yield (component 'layout/grid/item')}}
  </div>
  
  */
  {
    "id": "Qra2xbZt",
    "block": "{\"symbols\":[\"@gridSize\",\"@gapSize\",\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"layout-grid\"],[16,5,[30,[36,0],null,[[\"grid-gap-size\",\"grid-size\"],[[32,2],[32,1]]]]],[17,3],[12],[2,\"\\n  \"],[18,4,[[30,[36,1],[\"layout/grid/item\"],null]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"layout-css-var\",\"component\"]}",
    "moduleName": "ember-layout-components/components/layout/grid.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});