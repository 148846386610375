define("ember-layout-components/components/layout/cluster/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class={{layout-join-classes
        'layout-cluster-item'
        (layout-class-if @float "right" "layout-cluster-item--right")
        (layout-class-if @float "left" "layout-cluster-item--left")
        (layout-class-if @grow true "layout-cluster-item--grow")
      }}
    ...attributes
  >{{yield}}</div>
  
  */
  {
    "id": "bE6AVFyU",
    "block": "{\"symbols\":[\"@grow\",\"@float\",\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[16,0,[30,[36,1],[\"layout-cluster-item\",[30,[36,0],[[32,2],\"right\",\"layout-cluster-item--right\"],null],[30,[36,0],[[32,2],\"left\",\"layout-cluster-item--left\"],null],[30,[36,0],[[32,1],true,\"layout-cluster-item--grow\"],null]],null]],[17,3],[12],[18,4,null],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"layout-class-if\",\"layout-join-classes\"]}",
    "moduleName": "ember-layout-components/components/layout/cluster/item.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});